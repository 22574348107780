import React from 'react';
import '../css/WatercolorBackgroundError.css';

const WatercolorBackgroundError = () => {
  return (
    <div className="watercolor-background">
      <div className="watercolor-blob-error blob1-error"></div>
      <div className="watercolor-blob-error blob2-error"></div>
      <div className="watercolor-blob-error blob3-error"></div>
      <div className="watercolor-blob-error blob4-error"></div>
      <div className="watercolor-blob-error blob5-error"></div>
    </div>
  );
};

export default WatercolorBackgroundError;