// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/css/NotFoundPage.css */

.not-found-container {
    min-height: 100vh;
    padding: 80px 20px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
}

.not-found-card {
    background-color: rgba(26, 26, 26, 0.95); /* Slight transparency for the card */
    padding: 40px 60px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Shadow effect */
    max-width: 500px;
    width: 100%;
}

.not-found-card h1 {
    font-size: 3em;
    color: #ff0000;
    margin-bottom: 20px;
}

.not-found-card p {
    font-size: 1.5em;
    color: #ddd; /* Lighter text color to contrast with the dark background */
    margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/css/NotFoundPage.css"],"names":[],"mappings":"AAAA,6BAA6B;;AAE7B;IACI,iBAAiB;IACjB,uBAAuB;IACvB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,wCAAwC,EAAE,qCAAqC;IAC/E,kBAAkB;IAClB,mBAAmB;IACnB,yCAAyC,EAAE,kBAAkB;IAC7D,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,WAAW,EAAE,4DAA4D;IACzE,gBAAgB;AACpB","sourcesContent":["/* src/css/NotFoundPage.css */\n\n.not-found-container {\n    min-height: 100vh;\n    padding: 80px 20px 40px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n    text-align: center;\n}\n\n.not-found-card {\n    background-color: rgba(26, 26, 26, 0.95); /* Slight transparency for the card */\n    padding: 40px 60px;\n    border-radius: 12px;\n    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Shadow effect */\n    max-width: 500px;\n    width: 100%;\n}\n\n.not-found-card h1 {\n    font-size: 3em;\n    color: #ff0000;\n    margin-bottom: 20px;\n}\n\n.not-found-card p {\n    font-size: 1.5em;\n    color: #ddd; /* Lighter text color to contrast with the dark background */\n    margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
