import React from 'react';
import '../css/WatercolorBackground.css';

const WatercolorBackground = () => {
  return (
    <div className="watercolor-background">
      <div className="watercolor-blob blob1"></div>
      <div className="watercolor-blob blob2"></div>
      <div className="watercolor-blob blob3"></div>
      <div className="watercolor-blob blob4"></div>
      <div className="watercolor-blob blob5"></div>
    </div>
  );
};

export default WatercolorBackground;